<template>
  <general-card class="summary-card">
    <h5 class="px-5 py-2 header-bg">
      <span v-if="campaignSummary">CAMPAIGN SUMMARY</span>
      <span v-if="budgetSummary || mediapalBudgetSummary">BUDGET SUMMARY</span>
      <span v-if="adsetSummary">GENERAL ADSET DETAILS</span>
      <span v-if="adsetTargeting">BUDGET & TARGETING DETAILS</span>
      <span v-if="general_facebook_ad_details">GENERAL AD DETAILS</span>
      <span v-if="facebook_ad_targeting_details">AD TARGETING DETAILS</span>
      <span v-if="generalAdaccountSummary">GENERAL ADACCOUNT DETAILS</span>
      <span v-if="adaccountFundingSummary">FUNDING DETAILS</span>
      <span v-if="audienceSummary">AUDIENCE SUMMARY</span>
    </h5>
    <v-row class="pa-5">
      <v-col cols="4">
        <h6 class="text--disabled">
          <span v-if="mediapalBudgetSummary">BUDGET</span>
          <span v-if="audienceSummary">AGE GROUPS</span>
          <span v-if="campaignSummary">STATUS</span>
          <span v-if="budgetSummary">
            <span v-if="budgetSummary.daily_budget !== undefined">BUDGET</span>
          </span>
          <span v-if="adsetSummary">NAME</span>
          <span v-if="general_facebook_ad_details">STATUS</span>
          <span v-if="facebook_ad_targeting_details">GEO-TARGETING</span>
          <span v-if="generalAdaccountSummary">STATUS</span>
          <span v-if="adaccountFundingSummary">CURRENCY</span>
          <span v-if="adsetTargeting">
            <span v-if="adsetTargeting.bid_strategy">BID STRATEGY</span>
          </span>
        </h6>
      </v-col>
      <v-col cols="8">
        <h6>
          <span v-if="adsetTargeting">{{
              adsetTargeting.bid_strategy ? adsetTargeting.bid_strategy.replaceAll("_", " ") : "NONE"
          }}</span>
          <span v-if="mediapalBudgetSummary">${{
              mediapalBudgetSummary.budget
          }}</span>
          <span v-if="campaignSummary">{{
              campaignSummary.effective_status
          }}</span>
          <span v-if="audienceSummary">{{
              audienceSummary.ageGroups
          }}</span>
          <span v-if="budgetSummary">{{
              currency && budgetSummary.daily_budget !== undefined
                ? currency.account_currency
                : null
          }}
            {{
                budgetSummary.daily_budget !== undefined
                  ? (budgetSummary.daily_budget / 100).toLocaleString("US")
                  : null
            }}</span>
          <span v-if="adsetSummary">{{ adsetSummary.name }}</span>
          <span v-if="adsetTargeting">
            <span v-for="(filter, i) in adsetTargeting.targeting
            .brand_safety_content_filter_levels" :key="i">
              <span class="mr-1">{{ filter ? filter.replaceAll("_", " ") : "NONE" }},</span>
            </span>
          </span>
          <span v-if="general_facebook_ad_details">{{
              general_facebook_ad_details.effective_status.replaceAll("_", " ")
          }}</span>
          <span v-if="facebook_ad_targeting_details">
            <span class="mr-1" v-for="(location, i) in facebook_ad_targeting_details.targeting
            .geo_locations.countries" :key="i">{{ location }},</span>

            <span class="mr-1" v-for="(location, i) in facebook_ad_targeting_details.targeting
            .geo_locations.cities" :key="i">{{ location.name }} - {{ location.country }} (+
              {{ location.radius }} {{ location.distance_unit }}s),
            </span>
          </span>
          <span v-if="generalAdaccountSummary">
            {{
                generalAdaccountSummary.account_status == 1
                  ? "ACTIVE"
                  : generalAdaccountSummary.account_status == 2
                    ? "DISABLED"
                    : generalAdaccountSummary.account_status == 3
                      ? "UNSETTLED"
                      : generalAdaccountSummary.account_status == 7
                        ? "PENDING RISK REVIEW"
                        : generalAdaccountSummary.account_status == 8
                          ? "PENDING SETTLEMENT"
                          : generalAdaccountSummary.account_status == 9
                            ? "IN GRACE PERIOD"
                            : generalAdaccountSummary.account_status == 100
                              ? "PENDING CLOSURE"
                              : generalAdaccountSummary.account_status == 101
                                ? "CLOSED"
                                : generalAdaccountSummary.account_status == 201
                                  ? "ANY ACTIVE"
                                  : generalAdaccountSummary.account_status == 202
                                    ? "ANY CLOSED"
                                    : null
            }}
          </span>
          <span v-if="adaccountFundingSummary">{{
              adaccountFundingSummary.currency
          }}</span>
          <span v-if="generalAdaccountSummary">
            {{
                generalAdaccountSummary.account_status == 1
                  ? "ACTIVE"
                  : generalAdaccountSummary.account_status == 2
                    ? "DISABLED"
                    : generalAdaccountSummary.account_status == 3
                      ? "UNSETTLED"
                      : generalAdaccountSummary.account_status == 7
                        ? "PENDING RISK REVIEW"
                        : generalAdaccountSummary.account_status == 8
                          ? "PENDING SETTLEMENT"
                          : generalAdaccountSummary.account_status == 9
                            ? "IN GRACE PERIOD"
                            : generalAdaccountSummary.account_status == 100
                              ? "PENDING CLOSURE"
                              : generalAdaccountSummary.account_status == 101
                                ? "CLOSED"
                                : generalAdaccountSummary.account_status == 201
                                  ? "ANY ACTIVE"
                                  : generalAdaccountSummary.account_status == 202
                                    ? "ANY CLOSED"
                                    : null
            }}
          </span>
        </h6>
      </v-col>
      <v-col cols="4">
        <h6 class="text--disabled">
          <span v-if="mediapalBudgetSummary">CHARGE BY</span>
          <span v-if="campaignSummary">OBJECTIVE</span>
          <span v-if="audienceSummary">GENDER</span>
          <span v-if="budgetSummary">BUDGET REMAINING</span>
          <span v-if="adsetSummary">STATUS</span>
          <span v-if="general_facebook_ad_details">CREATED TIME</span>
          <span v-if="facebook_ad_targeting_details">TARGET AGE RANGE</span>
          <span v-if="generalAdaccountSummary">CREATED TIME</span>
          <span v-if="adaccountFundingSummary">AMOUNT SPENT</span>
          <span v-if="adsetTargeting">BUDGET REMAINING</span>
        </h6>
      </v-col>
      <v-col cols="8">
        <h6>
          <span v-if="adsetTargeting">
            $ {{ adsetTargeting.budget_remaining ? parseInt(adsetTargeting.budget_remaining / 100) : null }}
          </span>
          <span v-if="mediapalBudgetSummary">
            {{ mediapalBudgetSummary.chargeBy }}
          </span>
          <span v-if="audienceSummary">
            {{ audienceSummary.gender }}
          </span>
          <span v-if="campaignSummary">
            {{ campaignSummary.objective.replaceAll("_", " ") }}
          </span>
          <span v-if="budgetSummary">
            {{ currency ? currency.account_currency : null }}
            {{ (budgetSummary.budget_remaining / 100).toLocaleString("US") }}
          </span>
          <span v-if="adsetSummary">{{ adsetSummary.status }}</span>
          <span v-if="general_facebook_ad_details">{{
              new Date(general_facebook_ad_details.created_time).toDateString()
          }}</span>
          <span v-if="facebook_ad_targeting_details">
            {{ facebook_ad_targeting_details.targeting.age_min }} -
            {{ facebook_ad_targeting_details.targeting.age_max }}
          </span>
          <span v-if="generalAdaccountSummary">{{
              new Date(generalAdaccountSummary.created_time).toDateString()
          }}</span>
          <span v-if="adaccountFundingSummary">{{ adaccountFundingSummary.currency }}
            {{
                (adaccountFundingSummary.amount_spent / 100).toLocaleString("US")
            }}</span>
        </h6>
      </v-col>
      <v-col cols="4">
        <h6 class="text--disabled">
          <span v-if="mediapalBudgetSummary">CPC</span>
          <span v-if="generalAdaccountSummary">TIMEZONE</span>
          <span v-if="audienceSummary">DEMOGRAPHICS</span>
          <span v-if="adaccountFundingSummary">BALANCE</span>
          <span v-if="general_facebook_ad_details">LAST UPDATED</span>
          <span v-if="adsetSummary">DESTINATION TYPE</span>
          <span v-if="adsetTargeting">
            <span v-if="adsetTargeting.lifetime_budget">LIFETIME BUDGET</span>
          </span>
        </h6>
      </v-col>
      <v-col cols="8">
        <span v-if="audienceSummary">
          <h6><span class="text--disabled">Exclude:</span> {{ audienceSummary.demographics.exclude }}</h6>
          <h6><span class="text--disabled">Include:</span> {{ audienceSummary.demographics.include }}</h6>
        </span>
        <h6 v-if="adsetTargeting">$ {{ adsetTargeting.lifetime_budget ? parseInt(adsetTargeting.lifetime_budget) / 100 :
            0
        }}</h6>
        <h6 v-if="adsetSummary">{{ adsetSummary.destination_type }}</h6>
        <h6 v-if="generalAdaccountSummary">
          {{ generalAdaccountSummary.timezone_name }}
        </h6>
        <h6 v-if="mediapalBudgetSummary">
          {{ mediapalBudgetSummary.CPC }}
        </h6>
        <h6 v-if="adaccountFundingSummary">
          {{ adaccountFundingSummary.currency }}
          {{ (adaccountFundingSummary.balance / 100).toLocaleString("US") }}
        </h6>
        <h6 v-if="general_facebook_ad_details">
          {{
              new Date(general_facebook_ad_details.updated_time).toDateString()
          }}
        </h6>
      </v-col>
      <v-col cols="4" class="d-flex align-center">
        <h6 class="text--disabled">
          <span v-if="mediapalBudgetSummary">FREQUENCY CAP</span>
          <span v-if="audienceSummary">LOCATIONS</span>
          <span v-if="campaignSummary">START TIME</span>
          <span v-if="budgetSummary">{{
              budgetSummary.bid_strategy ? "BID STRATEGY" : null
          }}</span>
          <span v-if="facebook_ad_targeting_details">INTERESTS</span>
          <span v-if="adsetSummary">OPTIMIZATION GOAL</span>
          <span v-if="generalAdaccountSummary">
            <span v-if="generalAdaccountSummary.business_country_code">COUNTRY</span>
          </span>
          <span v-if="adaccountFundingSummary">IS PREPAY ACCOUNT</span>
        </h6>
      </v-col>
      <v-col cols="8">
        <span v-if="audienceSummary">
          <h6><span class="text--disabled">Exclude:</span> {{ audienceSummary.location.exclude }}</h6>
          <h6><span class="text--disabled">Include:</span> {{ audienceSummary.location.include }}</h6>
        </span>
        <h6 v-if="campaignSummary">
          {{ new Date(campaignSummary.start_time).toDateString() }}
        </h6>
        <span v-if="mediapalBudgetSummary">
          <span v-for="fc, i in mediapalBudgetSummary.frequencyCap" :key="i" class="text-caption font-weight-bold">
            {{ fc }},
          </span>
        </span>
        <span v-if="budgetSummary">
          <h6 v-if="budgetSummary.bid_strategy">
            {{ budgetSummary.bid_strategy.replaceAll("_", " ") }}
          </h6>
        </span>
        <h6 v-if="adsetSummary">
          {{ adsetSummary.optimization_goal.replaceAll("_", " ") }}
        </h6>
        <h6>
          <span v-if="facebook_ad_targeting_details">
            <span v-if="
              facebook_ad_targeting_details.targeting.flexible_spec !==
              undefined
            ">
              <span v-for="(filter, i) in facebook_ad_targeting_details.targeting
              .flexible_spec[0].interests" :key="i">
                <span> {{ filter.name }},</span>
              </span>
            </span>
          </span>
        </h6>
        <h6 v-if="general_facebook_ad_details">
          {{ general_facebook_ad_details.preview_shareable_link }}
        </h6>
        <h6 v-if="generalAdaccountSummary">
          {{ generalAdaccountSummary.business_country_code }}
        </h6>
        <h6 v-if="adaccountFundingSummary" class="text-capitalize">
          {{ adaccountFundingSummary.is_prepay_account }}
        </h6>
      </v-col>
      <v-col cols="4" v-if="generalAdaccountSummary">
        <h6 class="text--disabled" v-if="generalAdaccountSummary.funding_source_details">
          TYPE
        </h6>
      </v-col>
      <v-col cols="8" v-if="generalAdaccountSummary">
        <h6 v-if="generalAdaccountSummary.funding_source_details">
          {{
              generalAdaccountSummary.funding_source_details.type == 0
                ? "UNSET"
                : generalAdaccountSummary.funding_source_details.type == 1
                  ? "CREDIT CARD"
                  : generalAdaccountSummary.funding_source_details.type == 2
                    ? "FACEBOOK WALLET"
                    : generalAdaccountSummary.funding_source_details.type == 3
                      ? "FACEBOOK PAID CREDIT"
                      : generalAdaccountSummary.funding_source_details.type == 4
                        ? "FACEBOOK EXTENDED CREDIT"
                        : generalAdaccountSummary.funding_source_details.type == 5
                          ? "ORDER"
                          : generalAdaccountSummary.funding_source_details.type == 6
                            ? "INVOICE"
                            : generalAdaccountSummary.funding_source_details.type == 7
                              ? "FACEBOOK TOKEN"
                              : generalAdaccountSummary.funding_source_details.type == 8
                                ? "EXTERNAL FUNDING"
                                : generalAdaccountSummary.funding_source_details.type == 9
                                  ? "FEE"
                                  : generalAdaccountSummary.funding_source_details.type == 10
                                    ? "FX"
                                    : generalAdaccountSummary.funding_source_details.type == 11
                                      ? "DISCOUNT"
                                      : generalAdaccountSummary.funding_source_details.type == 12
                                        ? "PAYPAL TOKEN"
                                        : generalAdaccountSummary.funding_source_details.type == 13
                                          ? "PAYPAL BILLING AGREEMENT"
                                          : generalAdaccountSummary.funding_source_details.type == 14
                                            ? "FS NULL"
                                            : generalAdaccountSummary.funding_source_details.type == 15
                                              ? "EXTERNAL DEPOSIT"
                                              : generalAdaccountSummary.funding_source_details.type == 16
                                                ? "TAX"
                                                : generalAdaccountSummary.funding_source_details.type == 17
                                                  ? "DIRECT DEBIT"
                                                  : generalAdaccountSummary.funding_source_details.type == 18
                                                    ? "DUMMY"
                                                    : generalAdaccountSummary.funding_source_details.type == 19
                                                      ? "ALTPAY"
                                                      : generalAdaccountSummary.funding_source_details.type == 20
                                                        ? "STORED BALANCE"
                                                        : null
          }}
        </h6>
      </v-col>
      <v-col cols="4">
        <h6 class="text--disabled">
          <span v-if="campaignSummary">LAST UPDATED</span>
          <span v-if="budgetSummary">BUYING TYPE</span>
          <span v-if="adsetSummary">BILLING EVENT</span>
          <span v-if="adsetTargeting">GEO-LOCATIONS</span>
          <span v-if="generalAdaccountSummary">
            <span v-if="generalAdaccountSummary.business_name">BUSINESS NAME</span>
          </span>
          <span v-if="adaccountFundingSummary">MIN. DAILY BUDGET</span>
        </h6>
      </v-col>
      <v-col cols="8">
        <h6 v-if="campaignSummary">
          {{ new Date(campaignSummary.updated_time).toDateString() }}
        </h6>
        <h6 v-if="budgetSummary">{{ budgetSummary.buying_type }}</h6>
        <h6 v-if="adsetSummary">{{ adsetSummary.billing_event }}</h6>
        <span v-if="adsetTargeting">
          <h6 v-for="(filter, i) in adsetTargeting.targeting.geo_locations
          .countries" :key="i">
            {{ filter }}
          </h6>
          <h6 v-for="(filter, i) in adsetTargeting.targeting.geo_locations.cities" :key="i">
            {{ filter.name }} - {{ filter.country }} (+ {{ filter.radius }}
            {{ filter.distance_unit }}s),
          </h6>
        </span>
        <h6 v-if="generalAdaccountSummary">
          {{ generalAdaccountSummary.business_name }}
        </h6>
        <h6 v-if="adaccountFundingSummary">
          {{ adaccountFundingSummary.currency }}
          {{ adaccountFundingSummary.min_daily_budget / 100 }}
        </h6>
      </v-col>
      <v-col cols="4">
        <h6 class="text--disabled">
          <span v-if="campaignSummary">SPECIAL AD CATEGORY</span>
          <span v-if="budgetSummary">SPEND CAP</span>
          <span v-if="adsetSummary">STARTED</span>
          <span v-if="adsetTargeting">LOCATION TYPES</span>
          <span v-if="generalAdaccountSummary">
            <span v-if="generalAdaccountSummary.business_city">CITY</span>
          </span>
          <span v-if="adaccountFundingSummary">SPEND CAP</span>
        </h6>
      </v-col>
      <v-col cols="8">
        <h6 v-if="campaignSummary">
          {{ campaignSummary.special_ad_category }}
        </h6>
        <h6>
          <span v-if="budgetSummary">
            {{ currency ? currency.account_currency : null }}
            {{
                budgetSummary.spend_cap
                  ? (budgetSummary.spend_cap / 100).toLocaleString("US")
                  : "0"
            }}
          </span>
        </h6>
        <h6 v-if="adsetSummary">
          {{ new Date(adsetSummary.start_time).toDateString() }}
        </h6>
        <span v-if="adsetTargeting">
          <h6 v-for="(filter, i) in adsetTargeting.targeting.geo_locations
          .location_types" :key="i">
            {{ filter.toUpperCase() }}
          </h6>
        </span>
        <h6 v-if="generalAdaccountSummary">
          {{ generalAdaccountSummary.business_city }}
        </h6>
        <h6 v-if="adaccountFundingSummary">
          {{ adaccountFundingSummary.currency }}
          {{ adaccountFundingSummary.spend_cap }}
        </h6>
      </v-col>
      <v-col cols="4">
        <h6 class="text--disabled">
          <span v-if="adsetTargeting">
            <span v-if="adsetTargeting.targeting.flexible_spec !== undefined">
              BEHAVIORS
            </span>
          </span>
          <span v-if="adsetSummary">ENDED</span>
          <span v-if="generalAdaccountSummary">
            <span v-if="generalAdaccountSummary.business_street">STREET</span>
          </span>
          <span v-if="adaccountFundingSummary">MIN. CAMPAIGN GROUP SPEND</span>
        </h6>
      </v-col>
      <v-col cols="8">
        <h6 v-if="adsetTargeting">
          <span v-if="adsetTargeting.targeting.flexible_spec !== undefined">
            <span v-for="(filter, i) in adsetTargeting.targeting.flexible_spec[0]
            .behaviors" :key="i">{{ filter.name }},
            </span>
          </span>
        </h6>
        <h6 v-if="adsetSummary">
          {{ new Date(adsetSummary.end_time).toDateString() }}
        </h6>
        <h6 v-if="generalAdaccountSummary">
          {{ generalAdaccountSummary.business_street }}
        </h6>
        <h6 v-if="adaccountFundingSummary">
          {{ adaccountFundingSummary.currency }}
          {{
              (
                adaccountFundingSummary.min_campaign_group_spend_cap / 100
              ).toLocaleString("US")
          }}
        </h6>
      </v-col>
      <v-col cols="4">
        <h6 v-if="adsetTargeting" class="text--disabled">TARGET AGE</h6>
      </v-col>
      <v-col cols="8">
        <h6 v-if="adsetTargeting">{{ adsetTargeting.targeting.age_min }} - {{ adsetTargeting.targeting.age_max }}</h6>
      </v-col>
      <v-col cols="4">
        <h6 v-if="adsetTargeting" class="text--disabled">
          <span v-if="'brand_safety_content_filter_levels' in adsetTargeting.targeting">BRAND SAFETY FILTERS</span>
        </h6>
      </v-col>
      <v-col cols="8">
        <h6 v-if="adsetTargeting">
          <span v-for="(filter, i) in adsetTargeting.targeting
          .brand_safety_content_filter_levels" :key="i">
            <span class="mr-1">{{ filter.replaceAll("_", " ") }},</span>
          </span>
        </h6>
      </v-col>
    </v-row>
  </general-card>
</template>

<script>
import GeneralCard from "./GeneralCard.vue";

export default {
  data: () => ({
    isFacebook: false,
  }),
  components: {
    GeneralCard,
  },
  props: {
    budgetSummary: {
      type: Object,
    },
    mediapalBudgetSummary: {
      type: Object,
    },
    campaignSummary: {
      type: Object,
    },
    audienceSummary: {
      type: Object
    },
    currency: {
      default: null,
      type: Object,
    },
    adsetSummary: {
      type: Object,
    },
    adsetTargeting: {
      type: Object,
    },
    general_facebook_ad_details: {
      type: Object,
    },
    facebook_ad_targeting_details: {
      type: Object,
    },
    generalAdaccountSummary: {
      type: Object,
    },
    adaccountFundingSummary: {
      type: Object,
    },
  },
  mounted() {
    this.$route.name == "facebook-campaign" ? (this.isFacebook = true) : null;
  },
};
</script>

<style>
/* .summary-card {
  height: 80%;
} */

.header-bg {
  background-color: #e1f0f4;
  color: #a3a2a2;
}
</style>

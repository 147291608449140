<template>
  <v-card>
    <v-list-item v-if="total">
      <v-list-item-content>
        <div class="total-title mb-2">
          <template v-if="!loading">{{ total.title }}</template>
          <v-skeleton-loader v-else type="text" width="150px" height="20px"></v-skeleton-loader>
        </div>
        <v-list-item-title class="stats">
          <template v-if="!loading">{{ total.stats.toLocaleString("US") }}</template>
          <v-skeleton-loader type="text" width="250px" height="30px"></v-skeleton-loader>
        </v-list-item-title>
        <MiniCardsGraph v-if="!loading" :data="graphdata" :color="color" />
        <div v-else class="graphs"></div>
      </v-list-item-content>

    </v-list-item>
    <v-list-item v-else>
      <v-list-item-content>
        <div class="total-title mb-2">
          <template v-if="!loading">{{ title }}</template>
          <v-skeleton-loader v-else type="text" width="150px" height="20px"></v-skeleton-loader>
        </div>
        <v-list-item-title class="stats">
          <template v-if="!loading">{{ currency }} {{ (stats ? stats * 1 : 0).toLocaleString("US") }}{{ percentage }}</template>
          <v-skeleton-loader v-else type="text" width="250px" height="30px"></v-skeleton-loader>
        </v-list-item-title>
        <MiniCardsGraph v-if="!loading" :data="graphdata"  :color="color"/>
        <div v-else class="graphs"></div>
      </v-list-item-content>

      <!-- <v-list-item-avatar tile size="40" color="#F5F7FB">
        <v-icon color="#E9559A"> mdi-chart-bell-curve-cumulative </v-icon>
      </v-list-item-avatar> -->
    </v-list-item>

    <!-- <v-card-actions>
      <v-list-item>
        <v-list-item-subtitle
          >
          <span class="percentage">
              <v-icon large color="#E9559A">
            mdi-menu-down
          </v-icon>
          {{ total.percentage }}% Over Last Month
          </span>
          </v-list-item-subtitle
        >
      </v-list-item>
    </v-card-actions> -->
  </v-card>
</template>

<script>
import MiniCardsGraph from '../../Home/MiniCardsGraph.vue';
export default {
  props: {
    stats:{},
    title: String,
    currency: String,
    percentage: String,
    total: Object,
    graphdata: Array,
    color: {
      type: String,
      default: "#80CBC4"
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    MiniCardsGraph
  }
};
</script>

<style lang="sass">
.stats
  font-weight: bold
  font-size: 1.5rem

.total-title
  font-weight: bold
  text-transform: uppercase
  color: #B5B5B5
  font-size: 0.7rem

.v-list-item
  min-height: 0

.v-card__actions
  padding: 0

.graphs
  width: 100%
  height: 50px
  background-color: #d2cfcf
  border-radius: 25px
  animation: fadeIn 1s linear infinite alternate
  @keyframes fadeIn 
    0% 
      background-color: hsl(200, 20%, 80%)
    100%
      background-color: hsl(200, 20%, 95%)

</style>

